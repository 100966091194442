import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Text from '../Text';
import Button from '../Button';
import Container from '../Container';
import Html from '../Layout/Html';
import Link from '../Link';

const Wrapper = styled(Flex).attrs(() => ({
  py: [3, 4, 5],
}))``;

const CTA = ({ title, description, buttons, bg, color }) => {
  return (
    <Wrapper bg={bg}>
      <Container>
        <Flex alignItems="center" flexDirection="column">
          {title && (
            <Text
              textAlign="center"
              fontSize={[5, 6]}
              p={3}
              color={color}
              as="h2"
            >
              {title}
            </Text>
          )}
          {description && (
            <Text textAlign="center" p={3} color={color}>
              <Html
                dangerouslySetInnerHTML={{ __html: description }}
                color={color}
              />
            </Text>
          )}
          {buttons && (
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              p={3}
              alignItems="center"
              m={-2}
            >
              {buttons.map((button, idx) => {
                return (
                  <Box p={2} key={idx}>
                    <Link to={button?.link?.url || ''}>
                      <Button variant={button?.variant?.toLowerCase()}>
                        {button.text}
                      </Button>
                    </Link>
                  </Box>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Container>
    </Wrapper>
  );
};

CTA.defaultProps = {
  color: 'white',
  bg: 'valhalla',
};

export default CTA;
