import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Container from '../Container';
import Text from '../Text';
import Link from '../Link';
import Hr from '../Layout/Hr';
import { FiChevronRight } from 'react-icons/fi';
import Img from 'gatsby-image';
import { htmlCss } from '../Layout/Html';

const Wrapper = styled(Box)``;
Wrapper.defaultProps = {
  py: [5, 6, 7],
};

const Title = styled(Text)``;
Title.defaultProps = {
  color: 'valhalla',
  as: 'h2',
  fontSize: [5, 6],
};

const Heading = styled(Text)`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
Heading.defaultProps = {
  color: 'chestnutRose',
};

const Description = styled(Text)`
  ${htmlCss}
`;
Description.defaultProps = {
  variant: 'secondary',
  as: 'div',
};

const HomeAboutSection = ({
  bg,
  imageFluid,
  heading,
  title,
  htmlDescription,
  shouldInvertOrder,
  button,
  ...props
}) => {
  return (
    <Wrapper bg={bg} {...props}>
      <Container>
        <Flex
          m={[-3, -4, -5]}
          alignItems="center"
          flexWrap="wrap"
          flexDirection={shouldInvertOrder ? 'row-reverse' : 'row'}
        >
          <Box p={[3, 4, 5]} width={[1, null, 1 / 2]}>
            <Img fluid={imageFluid} />
          </Box>
          <Box p={[3, 4, 5]} width={[1, null, 1 / 2]}>
            {heading && (
              <Heading>
                {heading} <Hr ml={3} width={1} height={'2px'} />
              </Heading>
            )}
            {title && <Title mb={3}>{title}</Title>}
            {htmlDescription && (
              <Description
                mb={4}
                dangerouslySetInnerHTML={{ __html: htmlDescription }}
              />
            )}
            {button && (
              <Link to={button.link.url}>
                <Flex alignItems="center">
                  {button.text} <FiChevronRight />
                </Flex>
              </Link>
            )}
          </Box>
        </Flex>
      </Container>
    </Wrapper>
  );
};

HomeAboutSection.propTypes = {};

export default HomeAboutSection;
