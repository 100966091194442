import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Alert from '../Alert';
import Text from '../Text';
import Html from '../Layout/Html';
import Link from '../Link';
import Container from '../Container';
import { Box } from 'rebass';

const Announcement = () => {
  const { allPrismicAnnouncement } = useStaticQuery(graphql`
    {
      allPrismicAnnouncement {
        nodes {
          data {
            title {
              text
            }
            description {
              html
            }
            link {
              url
              target
              type
              slug
              uid
              link_type
            }
            status
          }
        }
      }
    }
  `);
  const node = allPrismicAnnouncement?.nodes?.[0];
  const isActive = node?.data?.status;
  const hasLink = node?.data?.link?.url !== '';
  if (isActive && (node?.data?.title?.text || node?.data?.description?.html))
    return (
      <Link
        disabled={!hasLink}
        {...node?.data?.link}
        to={node?.data?.link?.url}
      >
        <Box bg="grays.100">
          <Container>
            <Alert
              type="warning"
              bg="transparent"
              color="secondary"
              iconColor="chestnutRose"
              css={{ boxShadow: 'none' }}
            >
              {node?.data?.title?.text && (
                <Text fontWeight="500" color="chestnutRose">
                  {node.data.title.text}
                </Text>
              )}
              {node?.data?.description?.html && (
                <Html
                  dangerouslySetInnerHTML={{
                    __html: node?.data?.description?.html,
                  }}
                />
              )}
            </Alert>
          </Container>
        </Box>
      </Link>
    );
  return null;
};

export default Announcement;
