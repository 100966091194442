import React from 'react';
import { Portal } from 'reakit/Portal';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import AspectRatio from '../AspectRatio';
import Text from '../Text';

const StyledDialog = styled(Dialog)`
  position: fixed;
  width: 100%;
  max-width: 1024px;
  margin: 24px 0;
  padding: 0 24px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
`;

const Backdrop = styled(DialogBackdrop)`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledPlayer = styled(ReactPlayer)``;

const VideoModal = ({ url, isVisible }) => {
  const dialog = useDialogState({ visible: isVisible });
  return (
    <>
      <Portal>
        <Backdrop {...dialog} visible={isVisible} />
      </Portal>
      <StyledDialog
        {...dialog}
        visible={isVisible}
        aria-label="Testimonial video"
      >
        <Text
          color="white"
          textAlign="right"
          mb={3}
          style={{ cursor: 'pointer' }}
        >
          close ×
        </Text>
        <AspectRatio width={16} height={9}>
          {isVisible && (
            <StyledPlayer
              autoplay
              playing={isVisible}
              controls
              width="100%"
              height="100%"
              url={url}
            />
          )}
        </AspectRatio>
      </StyledDialog>
    </>
  );
};

export default VideoModal;
