import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import Card from '../Card';
import Button from '../Button';
import Text from '../Text';
import Img from 'gatsby-image';
import Link from '../Link';

const Title = styled(Text).attrs(() => ({
  fontSize: 1,
}))`
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
`;

const Description = styled(Text)`
  text-align: center;
`;
Description.defaultProps = {
  variant: 'secondary',
};

const Image = styled(Img)`
  width: 100%;
  border-radius: 5px;
`;

const HeroCard = ({ items, button }) => {
  return (
    <Card p={6}>
      <Flex m={-3} flexWrap="wrap">
        {items.map((item, idx) => (
          <Flex
            flexDirection="column"
            alignItems="center"
            width={[1, 1 / 2, 1 / 3]}
            p={3}
            key={idx}
          >
            <Image fluid={item.imageFluid} />
            <Title my={3}>{item.title}</Title>
            <Description>{item.description}</Description>
          </Flex>
        ))}
      </Flex>
      <Flex justifyContent="center" mt={5}>
        <Link to={button?.link?.url || ''}>
          <Button>{button?.text}</Button>
        </Link>
      </Flex>
    </Card>
  );
};

HeroCard.defaultProps = {};

HeroCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imageFluid: PropTypes.object,
    }),
  ),
  button: PropTypes.shape({
    path: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default HeroCard;
