import React from 'react';
import TimelineHeader from './Header';
import Container from '../Container';
import TimelineItem from './Item';

const Timeline = ({ title, description, steps }) => {
  return (
    <Container maxWidth={1140}>
      <TimelineHeader title={title} description={description} mb={5} />
      {steps.map((step, i) => {
        const isLast = i === steps.length - 1;
        return <TimelineItem key={step.id} isLast={isLast} {...step} />;
      })}
    </Container>
  );
};

Timeline.propTypes = {};

export default Timeline;
