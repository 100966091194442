import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import CoreText from '../Text';

const Text = styled(CoreText)`
  text-align: center;
`;

const TimelineHeader = ({ title, description, ...props }) => {
  return (
    <Flex flexDirection="column" alignItems="center" {...props}>
      <Text mb={3} as="h2" color="valhalla" fontSize={[4, 5, 6]}>
        {title}
      </Text>
      <Text variant="secondary">{description}</Text>
    </Flex>
  );
};

TimelineHeader.defaultProps = {
  title: 'The Right Path For You',
  description:
    'Our school has various ways of helping you heal, transform and elevate your consciousness.',
};

export default TimelineHeader;
