import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import Text from '../Text';
import Container from '../Container';
import { FiPlayCircle } from 'react-icons/fi';
import VideoModal from './VideoModal';

const Icon = styled(FiPlayCircle)`
  color: white;
  transition: 0.16s;
`;

const Section = styled(Box).attrs({
  as: 'section',
})`
  height: 400px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0.78;
    z-index: 1;
    background-color: ${({ bg }) => bg};
  }
  & > :not(img) {
    z-index: 2;
    position: relative;
  }
  &:hover {
    ${Icon} {
      stroke-width: 1.4;
      fill: ${({ theme }) => theme.colors.valhalla};
    }
  }
`;

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute !important;
  object-fit: cover;
  z-index: 0 !important;
`;

const VideoTestimonialsSection = ({
  title,
  description,
  bg,
  imageFluid,
  videoUrl,
}) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <Section py={[3, 4, 5]} bg={bg} onClick={() => toggleModal(!isModalOpen)}>
      {imageFluid && <Image fluid={imageFluid} />}
      {isModalOpen && (
        <VideoModal
          isVisible={isModalOpen}
          onClose={() => toggleModal(false)}
          url={videoUrl}
        />
      )}
      <Container style={{ height: '100%' }}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <Icon strokeWidth={1} size={80} />
          <Text
            mb={3}
            textAlign="center"
            color="white"
            as="h2"
            fontSize={[5, 6]}
          >
            {title}
          </Text>
          <Text variant="secondary" textAlign="center" color="white">
            {description}
          </Text>
        </Flex>
      </Container>
    </Section>
  );
};

VideoTestimonialsSection.defaultProps = {
  bg: '#311E5E',
};

export default VideoTestimonialsSection;
