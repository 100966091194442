import React, { useState } from 'react';
import { Box } from 'rebass';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import HeroCard from '../components/Hero/Card';
import Timeline from '../components/Timeline';
import CTA from '../components/CTA';
import HomeAboutSection from '../components/About';
import VideoTestimonialsSection from '../components/VideoTestimonials';
import { graphql, useStaticQuery } from 'gatsby';
import usePreviewData from '../utils/usePreviewData';
import Announcement from '../components/Announcement';
import { top } from 'styled-system';
import styled from 'styled-components';

const AnnouncementWrapper = styled.div`
  ${top}
  position:absolute;
  left: 0;
  right: 0;
  z-index: 2;
`;

const IndexPage = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          meta_title
          meta_description
          meta_og_image {
            thumbnails {
              og {
                url
              }
            }
          }
          hero_title {
            text
          }
          hero_description {
            text
          }
          hero_image {
            fluid(maxWidth: 2000) {
              ...GatsbyPrismicImageFluid
            }
          }
          # hero_card
          body {
            ... on PrismicHomepageBodyHeroCards {
              primary {
                hero_cards__button_title
                hero_cards__button_link {
                  link_type
                  url
                  target
                }
              }
              items {
                hero_card_title {
                  text
                }
                hero_card_description {
                  text
                }
                hero_card_image {
                  fluid(maxWidth: 320) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
          timeline_title {
            text
          }
          timeline_description {
            text
          }
          ## timeline steps
          body1 {
            ... on Node {
              id
            }
            ... on PrismicHomepageBody1Step {
              primary {
                timeline__step_title {
                  text
                }
                timeline__step_icon {
                  url
                }
              }
              items {
                timeline__step__card_link {
                  link_type
                  url
                  target
                }
                timeline__step__card_icon {
                  url
                }
                timeline__step__card_title {
                  text
                }
                timeline__step__card_description {
                  text
                }
              }
            }
          }
          cta_title {
            text
          }
          cta_buttons {
            cta_buttons__button_variant
            cta_buttons__button_title
            cta_buttons__button_link {
              link_type
              url
              target
            }
          }
          the_institute_link {
            id
            url
            link_type
            slug
            target
          }
          the_institute_link_text
          the_institute_image {
            fluid(maxWidth: 720) {
              ...GatsbyPrismicImageFluid
            }
          }
          the_institute_title {
            text
          }
          the_institute_description {
            html
            text
          }
          the_founder_link {
            id
            url
            link_type
            slug
            target
          }
          the_founder_link_text
          the_founder_image {
            fluid(maxWidth: 720) {
              ...GatsbyPrismicImageFluid
            }
          }
          the_founder_title {
            text
          }
          the_founder_description {
            html
            text
          }
          the_founder_heading {
            text
          }
          testimonial_title {
            text
          }
          testimonial_cover {
            fluid(maxWidth: 1920) {
              ...GatsbyPrismicImageFluid
            }
          }
          testimonial_description {
            text
          }
          testimonial_video_url {
            url
          }
          contact_title {
            text
          }
          contact_description {
            html
          }
          contact_buttons {
            contact_buttons__button_variant
            contact_buttons__button_text
            contact_buttons__button_link {
              link_type
              url
              target
            }
          }
          newsletter_title {
            html
            text
          }
          newsletter_buttons {
            newsletter_buttons__button_variant
            newsletter_buttons__button_text
            newsletter_buttons__button_link {
              link_type
              url
              target
            }
          }
          newsletter_description {
            html
            text
          }
        }
      }
    }
  `);
  const liveData = usePreviewData({ prismicHomepage });
  const data = liveData?.prismicHomepage?.data;
  const heroProps = {
    title: data.hero_title.text,
    description: data.hero_description.text,
    imageFluid: data.hero_image.fluid,
  };
  const heroCardProps = {
    items: data.body[0].items.map(item => ({
      title: item.hero_card_title.text,
      description: item.hero_card_description.text,
      imageFluid: item.hero_card_image.fluid,
    })),
    button: {
      text: data.body[0].primary.hero_cards__button_title,
      link: data.body[0].primary.hero_cards__button_link,
    },
  };
  const timelineProps = {
    title: data.timeline_title.text,
    description: data.timeline_description.text,
    steps: data.body1.map(step => ({
      id: step.id,
      icon: step.primary.timeline__step_icon.url,
      title: step.primary.timeline__step_title.text,
      cards: step.items.map(card => ({
        icon: card.timeline__step__card_icon.url,
        link: card.timeline__step__card_link,
        title: card.timeline__step__card_title.text,
        description: card.timeline__step__card_description.text,
      })),
    })),
  };
  const ctaProps = {
    title: data.cta_title.text,
    buttons: data.cta_buttons.map(button => ({
      text: button.cta_buttons__button_title,
      variant: button.cta_buttons__button_variant,
      link: button.cta_buttons__button_link,
    })),
  };
  const contactCtaProps = {
    title: data.contact_title.text,
    description: data.contact_description.html,
    buttons: data.contact_buttons.map(button => ({
      text: button.contact_buttons__button_text,
      variant: button.contact_buttons__button_variant,
      link: button.contact_buttons__button_link,
    })),
  };
  const newsletterCtaProps = {
    title: data.newsletter_title.text,
    description: data.newsletter_description.html,
    buttons: data.newsletter_buttons.map(button => ({
      text: button.newsletter_buttons__button_text,
      variant: button.newsletter_buttons__button_variant,
      link: button.newsletter_buttons__button_link,
    })),
  };
  const theInstituteProps = {
    title: data.the_institute_title.text,
    htmlDescription: data.the_institute_description.html,
    imageFluid: data.the_institute_image.fluid,
    button: {
      link: data.the_institute_link,
      text: data.the_institute_link_text,
    },
  };
  const theFounderProps = {
    heading: data.the_founder_heading.text,
    title: data.the_founder_title.text,
    htmlDescription: data.the_founder_description.html,
    imageFluid: data.the_founder_image.fluid,
    button: {
      link: data.the_founder_link,
      text: data.the_founder_link_text,
    },
  };
  const videoTestimonialProps = {
    title: data.testimonial_title.text,
    description: data.testimonial_description.text,
    videoUrl: data.testimonial_video_url.url,
    imageFluid: data.testimonial_cover.fluid,
  };
  const [top, setTop] = useState(147);
  return (
    <Layout onHeaderHeight={setTop}>
      {top > 0 && (
        <AnnouncementWrapper top={top}>
          <Announcement />
        </AnnouncementWrapper>
      )}
      <SEO
        title={data?.meta_title || 'Home'}
        description={data?.meta_description}
        ogImage={data?.meta_og_image?.og?.url}
      />
      <Box mb={[5, 6, 7]}>
        <Hero {...heroProps}>
          <HeroCard {...heroCardProps} />
        </Hero>
      </Box>
      <Box mb={[5, 6, 7]}>
        <Timeline {...timelineProps} />
      </Box>
      <CTA {...ctaProps} />
      <HomeAboutSection {...theInstituteProps} />
      <HomeAboutSection {...theFounderProps} shouldInvertOrder />
      <VideoTestimonialsSection {...videoTestimonialProps} />
      <CTA color="valhalla" bg="white" {...contactCtaProps} />
      <CTA {...newsletterCtaProps} />
    </Layout>
  );
};

export default IndexPage;
