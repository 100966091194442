import React from 'react';
import { Flex, Box } from 'rebass';
import styled, { css } from 'styled-components';
import { width, height, space } from 'styled-system';
import Hr from '../Layout/Hr';
import Text from '../Text';
import Card from '../Card';
import Link from '../Link';
import { FiChevronRight } from 'react-icons/fi';

const Img = styled.img`
  ${space}
`;

const IconWrapper = styled(Flex)`
  ${width}
  ${height}
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
`;

const Icon = styled.img`
  ${width}
  ${height}
`;

const Arrow = styled.div`
  ${({ theme }) => css`
    width: 2px;
    height: 100%;
    flex: 1;
    background-color: ${theme.colors.valhalla};
    opacity: 0.3;
    position: relative;
    &:before {
      content: '⌄';
      font-size: 24px;
      font-weight: lighter;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 8px);
    }
  `}
`;

const Header = styled(Flex)`
  transform: translateY(-50%);
  align-items: center;
`;

const Title = styled(Text).attrs(() => ({
  as: 'h3',
  color: 'valhalla',
  fontSize: [3, 4],
}))`
  text-transform: uppercase;
`;

const iconSize = [64, 96, 128];

const TimelineItem = ({ icon, title, cards, isLast }) => {
  return (
    <Flex alignItems="stretch" mx={-2}>
      <Flex px={2} flexDirection="column" alignItems="center">
        <IconWrapper fontSize={4} width={iconSize} height={iconSize}>
          <Icon src={icon} width={iconSize.map(size => size / 2)} />
        </IconWrapper>
        {!isLast && <Arrow />}
      </Flex>
      <Box px={2} flex={1}>
        <Header mb={3} marginTop={iconSize.map(size => size / 2)}>
          <Title pr={3}>{title}</Title>
          <Hr width={1} height="1px" pl={5} />
        </Header>
        <Flex m={-2} flexWrap="wrap">
          {cards.map(card => (
            <Box width={[1, null, 1 / 2]} p={2} key={card.id}>
              <Link to={card?.link?.url || ''}>
                <Card p={[3, 4]}>
                  <Img mb={3} src={card.icon} />
                  <Text mb={3} css={{ textTransform: 'uppercase' }}>
                    {card.title}
                  </Text>
                  <Text variant="secondary" mb={3}>
                    {card.description}
                  </Text>
                  <Text
                    color="chestnutRose"
                    style={{ textTransform: 'uppercase' }}
                  >
                    <Flex alignItems="center">
                      Learn more <FiChevronRight size={16} />
                    </Flex>
                  </Text>
                </Card>
              </Link>
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};

TimelineItem.defaultProps = {
  title: 'Discover',
  cards: [
    {
      icon: '💡',
      id: 'card1',
      title: 'Find a Brennan Healing Science Practitioner',
      description:
        'All of our graduates have completed our 4-year training program, which qualifies each graduate to be a Brennan Healing Science Practitioner. Our practitioners are located all over the world.',
      cta: {
        text: 'Learn more',
        path: '/',
      },
    },
    {
      icon: '🛍️',
      id: 'card2',
      title: 'Find a Brennan Healing Science Practitioner',
      description:
        'All of our graduates have completed our 4-year training program, which qualifies each graduate to be a Brennan Healing Science Practitioner. Our practitioners are located all over the world.',
      cta: {
        text: 'Learn more',
        path: '/',
      },
    },
  ],
};

export default TimelineItem;
